import Store from '../ReactiveComponents/Store.js'

//
// DOM traversal utilities
//
const find = (selector, scope = document) => scope.querySelector(selector)
const findAll = (selector, scope = document) => [].slice.call(scope.querySelectorAll(selector))

//
// CSS prefixing
//
const prefixUtil = prefixString => target => `${prefixString}_${target}`

//
// Bind JS components to DOM elements via data-component
// Optionally attach shared svelte stores to enable communication between components
//

// This is loaded 2 independent times in sysmex & mysysmex. Big flaw for e.g. applyAll.
const components = {}
const stores = {}

const register = (name, component) => {
  components[name] = component
  window.components = components
  document.addEventListener('DOMContentLoaded', () => {
    apply(name)
  })
}

const apply = (name, scope = document) => {
  scope.querySelectorAll(`[data-component=${name}]`).forEach(el => {
    if (el.dataset.store) {
      components[name](el, getStore(el.dataset.store))
    } else {
      components[name](el)
    }
  })
}

const applyAll = (scope = document) => {
  window.components = components
  Object.keys(components).forEach(name => {
    apply(name, scope)
  })
}

const getStore = name => {
  stores[name] = stores[name] || new Store()
  return stores[name]
}

export {
  find,
  findAll,
  prefixUtil,
  register,
  apply,
  applyAll,
  getStore
}
