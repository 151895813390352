import { writable, get } from 'svelte/store'

export default class Store {
  constructor () {
    this.storeInstance = writable({})
    this.subscribe = this.storeInstance.subscribe
    this.set = this.storeInstance.set
    this.update = this.storeInstance.update
  }

  addItem (id, item) {
    this.update(state => { return { ...state, [id]: item } })
  }

  updateItem (id, item) {
    this.update(state => {
      const clonedState = { ...state }
      clonedState[id] = item
      return clonedState
    })
  }

  getItemById (id) {
    return get(this)[id]
  }
}
