import { register } from '../../../../Javascript/Sysmex/Utils'
import { tns } from '../../../../Javascript/Libraries/tiny-slider'

const Carousel = el => {
  tns(el.dataset.prefix, {
    container: el.querySelector('.' + el.dataset.prefix + 'carousel-inner'),
    items: 1,
    slideBy: 1,
    edgePadding: el.dataset.padding ? -15 : false,
    responsive: {
      768: {
        items: el.dataset.slidestoshow || 1,
        slideBy: el.dataset.slidestoscroll || 1
      }
    },
    autoplay: el.dataset.autoplay || false,
    nav: false,
    speed: 600,
    autoplayTimeout: 6000,
    loop: el.dataset.loop || true,
    prevButton: el.querySelector('.' + el.dataset.prefix + 'carousel-prev'),
    nextButton: el.querySelector('.' + el.dataset.prefix + 'carousel-next')
  })
}

register('Carousel', Carousel)
