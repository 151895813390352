import { register } from '../../../../Javascript/Sysmex/Utils'
import { tns } from '../../../../Javascript/Libraries/tiny-slider'

const GallerySlider = el => {
  const thumbLinks = el.querySelectorAll('.' + el.dataset.prefix + 'gallery-thumbnavi >div')
  const infoContainer = el.querySelector('.' + el.dataset.prefix + 'slides-info')
  const showNavSlides = 5
  const naviBlockwise = false // Slide the thumbnail navigation in entire blocks, e.g. show next 5 only when slide 6 is reached.
  const labels = JSON.parse(el.dataset.labels)

  const mainSlider = tns(el.dataset.prefix, {
    container: el.querySelector('.' + el.dataset.prefix + 'carousel-inner'),
    items: 1,
    slideBy: 1,
    autoplay: el.dataset.autoplay || false,
    nav: false,
    speed: 600,
    autoplayTimeout: 6000,
    loop: true,
    preventScrollOnTouch: 'auto',
    prevButton: el.querySelector('.' + el.dataset.prefix + 'carousel-prev'),
    nextButton: el.querySelector('.' + el.dataset.prefix + 'carousel-next')
  })

  const navSlider = tns(el.dataset.prefix, {
    container: el.querySelector('.' + el.dataset.prefix + 'gallery-thumbnavi'),
    axis: 'vertical',
    items: showNavSlides,
    gutter: 5,
    slideBy: showNavSlides,
    autoplay: false,
    nav: false,
    speed: 600,
    loop: false,
    prevButton: el.querySelector('.' + el.dataset.prefix + 'thumb-carousel-prev'),
    nextButton: el.querySelector('.' + el.dataset.prefix + 'thumb-carousel-next')
  })

  const slideSum = mainSlider.getInfo().slideCount
  let targetThumb

  const setInfoText = (current, sum) => {
    infoContainer.textContent = `${labels.image} ${current} ${labels.of} ${sum}`
  }

  mainSlider.events.on('indexChanged', e => {
    const index = e.index > slideSum ? 0 : e.index - 1
    const previous = e.indexCached - 1

    if (index >= 0 && index < thumbLinks.length) {
      if (thumbLinks[previous]) {
        thumbLinks[previous].classList.remove(el.dataset.prefix + 'is--current')
      }
      thumbLinks[index].classList.add(el.dataset.prefix + 'is--current')
    }

    if (naviBlockwise) {
      targetThumb = index - index % showNavSlides
    } else {
      targetThumb = index - Math.floor(showNavSlides / 2)
    }

    navSlider.goTo(targetThumb)
    setInfoText(index + 1, slideSum)
  })

  thumbLinks.forEach((thumb, index) => {
    thumb.addEventListener('click', e => {
      mainSlider.goTo(index)
    })
  })

  // Init
  setInfoText(1, slideSum)
  thumbLinks[0].classList.add(el.dataset.prefix + 'is--current')
}

register('GallerySlider', GallerySlider)
